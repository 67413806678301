/* 自定义icon */
@font-face {
    font-family: 'iconfont';  /* Project id 4538127 */
    src: url('//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.woff2?t=1727102199929') format('woff2'),
         url('//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.woff?t=1727102199929') format('woff'),
         url('//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.ttf?t=1727102199929') format('truetype');
  }
  
  .iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;}

.info-content .name {
    margin-bottom: 0 !important;
}

.look_more {
    color: var(--white-2);
    font-size: var(--fs-5);
    margin-bottom: 15px;
    white-space: nowrap;
    text-align: center;
    letter-spacing: -0.25px;
}

.english_name {
    color: var(--white-2);
    font-size: var(--fs-5);
    font-weight: var(--fw-600);
    margin-bottom: 0.5em;
    white-space: nowrap;
    text-align: center;
    letter-spacing: -0.25px;
}

.project_info {
    display: flex;
    height: 100%;
    width: 100%;
    line-height: 1.3;
    margin-bottom: 7px;
}

.timeline-item-title {
    margin-right: 10px !important;
    margin-bottom: 0 !important;
}

.project_director {
    color: var(--light-gray-50);
    font-weight: var(--fw-300);
    margin-right: 10px;
    transition: color var(--transition-1);
}

.project_link {
    color: var(--light-gray-50);
    font-weight: var(--fw-300);
    transition: color var(--transition-1);
    margin-right: 10px;
    transition: color var(--transition-1);
}

.project_link:hover,
.project_link:focus {
    color: var(--white-2);
    transition: color var(--transition-1);
}

.timeline-item span {
    display: inline;
}

.list-num li {
    list-style-type: disc;
    color: var(--light-gray);
}

.powered {
    text-align: center;
    margin-top: 0.5vh;
    font-size: var(--fs-8);
    font-weight: var(--fw-400);
    color: var(--white-3-light);
}

.powered_name {
    text-align: center;
    font-size: var(--fs-6);
    font-weight: var(--fw-600);
    color: var(--white-3-light);
    font-family: futura;
    margin-top: -5px;
}

.powered_name:hover {
    color: var(--vegas-gold);
}

.powered_by{
    margin-bottom: 1rem;
}

/* 悬浮球的基本样式 */
.floating-ball {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 4vh;
    height: 4vh;
    background-color: var(--border-gradient-onyx); /* 默认颜色 */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow-2) ;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    z-index: 1000;
  }

  .floating-ball::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--eerie-black-1);
    border-radius: inherit;
    z-index: -1;
  }
  
  /* 鼠标悬停时的效果 */
  .floating-ball:hover {
    transform: scale(1.1); /* 悬浮球放大 */
  }
  
  /* 小图标样式 */
  .ball-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
  }
  
  /* 切换时的旋转动画 */
  .floating-ball.clicked .ball-icon {
    animation: rotate 0.6s ease forwards;
  }

  
  
  /* 旋转动画定义 */
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

@media (min-width: 1px) {

    .english_name {
        text-align: left;
    }

}

@media (max-width: 1024px) {

    .powered_by{
        margin-bottom: 5rem;
    }
}

@media (min-width: 1024px) {

    .english_name {
        text-align: left;
    }

    .powered_by{
        margin-bottom: 2rem;
    }
}

@media (min-width: 580px) {

}

@media (min-width: 1250px) {

    .english_name {
        text-align: center;
    }
}