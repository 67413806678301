@font-face {
  font-family: iconfont;
  src: url("//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.woff2?t=1727102199929") format("woff2"), url("//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.woff?t=1727102199929") format("woff"), url("//at.alicdn.com/t/c/font_4538127_14sot0d8sl4.ttf?t=1727102199929") format("truetype");
}

.iconfont {
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: .2px;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;
  font-family: iconfont !important;
}

.info-content .name {
  margin-bottom: 0 !important;
}

.look_more {
  color: var(--white-2);
  font-size: var(--fs-5);
  white-space: nowrap;
  text-align: center;
  letter-spacing: -.25px;
  margin-bottom: 15px;
}

.english_name {
  color: var(--white-2);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  white-space: nowrap;
  text-align: center;
  letter-spacing: -.25px;
  margin-bottom: .5em;
}

.project_info {
  width: 100%;
  height: 100%;
  margin-bottom: 7px;
  line-height: 1.3;
  display: flex;
}

.timeline-item-title {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.project_director {
  color: var(--light-gray-50);
  font-weight: var(--fw-300);
  transition: color var(--transition-1);
  margin-right: 10px;
}

.project_link {
  color: var(--light-gray-50);
  font-weight: var(--fw-300);
  transition: color var(--transition-1);
  transition: color var(--transition-1);
  margin-right: 10px;
}

.project_link:hover, .project_link:focus {
  color: var(--white-2);
  transition: color var(--transition-1);
}

.timeline-item span {
  display: inline;
}

.list-num li {
  color: var(--light-gray);
  list-style-type: disc;
}

.powered {
  text-align: center;
  font-size: var(--fs-8);
  font-weight: var(--fw-400);
  color: var(--white-3-light);
  margin-top: .5vh;
}

.powered_name {
  text-align: center;
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  color: var(--white-3-light);
  margin-top: -5px;
  font-family: futura;
}

.powered_name:hover {
  color: var(--vegas-gold);
}

.powered_by {
  margin-bottom: 1rem;
}

.floating-ball {
  background-color: var(--border-gradient-onyx);
  box-shadow: var(--shadow-2);
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 4vh;
  height: 4vh;
  transition: transform .3s, background-color .3s;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-ball:before {
  content: "";
  background: var(--eerie-black-1);
  border-radius: inherit;
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.floating-ball:hover {
  transform: scale(1.1);
}

.ball-icon {
  font-size: 24px;
  transition: transform .3s;
}

.floating-ball.clicked .ball-icon {
  animation: .6s forwards rotate;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (width >= 1px) {
  .english_name {
    text-align: left;
  }
}

@media (width <= 1024px) {
  .powered_by {
    margin-bottom: 5rem;
  }
}

@media (width >= 1024px) {
  .english_name {
    text-align: left;
  }

  .powered_by {
    margin-bottom: 2rem;
  }
}

@media (width >= 1250px) {
  .english_name {
    text-align: center;
  }
}
/*# sourceMappingURL=index.a5f118e1.css.map */
